import React, { useState, useEffect } from "react";
import axios from "axios";
import Plot from "react-plotly.js";
import { Link } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import countBy from "lodash.countby";
import range from "lodash.range";
import photos2021 from "./photos2021.json";
import photos2022 from "./photos2022.json";
import photos2023 from "./photos2023.json";
import photos2024 from "./photos2024.json";
import "./Board.css";
import apiUrl from "./apiUrl";

const placeholders = [
  "https://image.shutterstock.com/image-vector/basketball-player-vector-silhouette-260nw-530749927.jpg",
  "https://www.stockvault.net/data/2018/12/22/258017/preview16.jpg",
  "https://shop.digitemb.com/wp-content/uploads/2019/06/V-20294-Basketball-Player-Running-with-Ball-Silhouette-Vector-Art.jpg",
  "https://www.sportsilhouettes.com/content/images/thumbs/0002830_basketball-player-12-sports-decor-silhouette-decals_550.gif",
  "https://ae01.alicdn.com/kf/HTB1FohERFXXXXXBXpXXq6xXFXXX5/14-1CM-12-5CM-Basketball-Player-Silhouette-Car-Sticker-Vinyl-S9-0208.jpg_Q90.jpg_.webp",
  "https://www.childrensilhouettes.com/content/images/thumbs/0002841_basketball-player-24-sports-decor-silhouette-decals_600.gif",
];

export default function Board({ year }) {
  const [data, setData] = useState(false);

  const photos = {
    2021: photos2021,
    2022: photos2022,
    2023: photos2023,
    2024: photos2024,
  }[year];

  useEffect(() => {
    setData(null);

    const fetchData = async () => {
      const result = await axios(
        `${apiUrl}/.netlify/functions/getVotes?year=${year}`
      );

      setData(result.data);
    };

    fetchData();
  }, [year]);

  if (!data)
    return (
      <div className="spinner">
        <SyncLoader />
      </div>
    );

  if (!data?.data?.length) {
    return (
      <>
        <div class="placeholders">
          <h1>{year} DNHQ Big Board</h1>

          {placeholders.map((el, i) => (
            <div className="placeholder" key={i}>
              <img src={el} alt="silhouette" />
              <h2>{i + 1}</h2>
            </div>
          ))}
        </div>
      </>
    );
  }

  const votes = data.data
    .map((el) => el.data.vote)
    .reduce((acc, vote) => {
      vote.forEach((name, i) => {
        acc[name] = acc[name] ? [...acc[name], i + 1] : [i + 1];
      });
      return acc;
    }, {});

  Object.keys(votes).forEach(
    (name) => (votes[name] = votes[name].sort((a, b) => a - b))
  );

  const xAxis = range(1, 40);
  const yAxis = [...xAxis].fill(0);

  const setHistogramData = (votes) => {
    const data = [...yAxis];
    const count = countBy(votes);
    Object.keys(count).forEach(
      (voteNum) => (data[voteNum - 1] = count[voteNum])
    );
    return data;
  };

  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
  const sortedVotes = Object.keys(votes)
    .reduce(
      (acc, name) => [
        ...acc,
        {
          name: name,
          votes: votes[name],
          avg: Math.round(average(votes[name]) * 100) / 100,
          photo: photos.find((el) => el.name === name)?.photo,
          histogram: setHistogramData(votes[name]),
        },
      ],
      []
    )
    .sort((a, b) => a.avg - b.avg);

  return (
    <div className="home-wrapper">
      <div className="big-board">
        <h1>{year} DNHQ Big Board</h1>
        {sortedVotes.map((vote, i) => (
          <div key={i} className="homepage-row">
            <div className={`photo photo--homepage photo--${year}`}>
              <img src={vote?.photo} alt="" />
              <div className="photoCaption">{vote?.name}</div>
              <div className="rank">
                <h2>{i + 1}</h2>
              </div>
            </div>
            <div>
              <Plot
                data={[{ type: "bar", x: xAxis, y: vote?.histogram }]}
                layout={{
                  width: 320,
                  height: 240,
                  title: `Avg vote: ${vote?.avg}`,
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="voters">
        <h2>Voters</h2>
        <ul>
          {data.data.map((el, i) =>
            el.data.anon === false ? (
              <li key={i}>
                <Link to={`/${year}/${el.ref["@ref"].id}`}>{el.data.user}</Link>
              </li>
            ) : (
              <li key={i}>{el.data.user}</li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}
