import React, { useState, useEffect } from "react";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import photos2021 from "./photos2021";
import photos2022 from "./photos2022";
import photos2023 from "./photos2023";
import photos2024 from "./photos2024";
import { useParams } from "react-router-dom";
import apiUrl from "./apiUrl";

export default function User({ year }) {
  const [data, setData] = useState(false);
  const { user } = useParams();

  const photos = {
    2021: photos2021,
    2022: photos2022,
    2023: photos2023,
    2024: photos2024,
  }[year];

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `${apiUrl}/.netlify/functions/getUser?year=${year}`,
        {
          params: {
            user: user,
          },
        }
      );

      setData(result.data);
    };

    fetchData();
  }, [user, year]);

  if (!data)
    return (
      <div className="spinner">
        <SyncLoader />
      </div>
    );

  return (
    <div className="rankedWrapper">
      <h2>{data.data.user ? `${data.data.user}'s ` : ""}Big Board</h2>
      <div className="ranked">
        {data.data.vote.map((el, i) => (
          <div className={`photo photo--${year}`} key={i}>
            <img
              alt={el}
              src={photos.find((photo) => photo.name === el)?.photo}
            />
            <div className="photoCaption">{el}</div>
            <div className="rank">
              <h2>{i + 1}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
