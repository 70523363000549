import "./App.css";
import Vote from "./Vote";
import User from "./User";
import Board from "./Board";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">2024</Link>
            </li>
            <li>
              <Link to="/2023">2023</Link>
            </li>
            <li>
              <Link to="/2022">2022</Link>
            </li>
            <li>
              <Link to="/2021">2021</Link>
            </li>
            <li>
              <Link to="/vote">Vote</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/vote" element={<Vote />} />

          <Route path="/2021/:user" element={<User year={2021} />} />

          <Route path="/2022/:user" element={<User year={2022} />} />

          <Route path="/2023/:user" element={<User year={2023} />} />

          <Route path="/2024/:user" element={<User year={2024} />} />

          <Route path="/2021" element={<Board year={2021} />} />

          <Route path="/2022" element={<Board year={2022} />} />

          <Route path="/2023" element={<Board year={2023} />} />

          <Route path="/" element={<Board year={2024} />} />
        </Routes>
      </div>
    </Router>
  );
}
