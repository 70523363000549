import "./Vote.css";
import photos from "./photos2024.json";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useCallback, useRef, useEffect } from "react";
import useStickyState from "./useStickyState";
import apiUrl from "./apiUrl";
import axios from "axios";

function Vote() {
  const [unUsed, setUnUsed] = useStickyState(photos, "unUsed2024");
  const [ranked, setRanked] = useStickyState([], "ranked2024");
  const [userName, setUserName] = useStickyState("", "userName2024");
  const [voted, setVoted] = useStickyState(false, "voted2024");

  useEffect(() => {
    if (window.location.search.includes("reset")) {
      setVoted(false);
      window.history.replaceState({}, "", "/vote");
    }

    // Ensure all photos are present in either ranked or un-used state.
    // (Ensures new players added will show up even for users who have already started arranging).
    photos.forEach((photo) => {
      if (
        !ranked.find((el) => el.id === photo.id) &&
        !unUsed.find((el) => el.id === photo.id)
      ) {
        setUnUsed([...unUsed, photo]);
      }
    });
  }, [setVoted, ranked, unUsed, setUnUsed]);

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }) => {
      if (!destination) return;

      let newUnUsed = [...unUsed];
      let newRanked = [...ranked];

      if (source.droppableId === "unUsed")
        newUnUsed = newUnUsed.filter((el) => el.id !== draggableId);
      if (source.droppableId === "ranked")
        newRanked = newRanked.filter((el) => el.id !== draggableId);

      if (destination.droppableId === "unUsed")
        newUnUsed.splice(
          destination.index,
          0,
          photos.find((el) => el.id === draggableId)
        );
      if (destination.droppableId === "ranked")
        newRanked.splice(
          destination.index,
          0,
          photos.find((el) => el.id === draggableId)
        );

      setUnUsed(newUnUsed);
      setRanked(newRanked);
    },
    [ranked, unUsed, setRanked, setUnUsed]
  );

  const userNameRef = useRef();

  const submitVote = async () => {
    // Draft is over, turn off voting.
    // alert("Draft already happened, voting is over.");
    // return;

    if (ranked.length < 9) {
      alert("Please rank at least 9 players");
      return;
    }

    const vote = ranked.map((el) => el.name);
    const voteString = vote.reduce(
      (acc, el, i) => `${acc}\n${i + 1}. ${el}`,
      ""
    );

    if (
      window.confirm(
        `Are you actually ${userName}, and is this for sure your vote?\n(note: ballots will be public this year)\n${voteString}`
      )
    ) {
      let response;
      try {
        response = await axios.post(`${apiUrl}/.netlify/functions/vote`, {
          user: userName,
          vote: vote,
          anon: false,
        });
      } catch (e) {
        alert("Something went wrong :(", e);
      }

      if (response && response.status === 200) {
        setVoted(true);
        alert("Success!");
      }
    }
  };

  return (
    <div className="vote">
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          {!voted && (
            <div className="userName">
              <label htmlFor="userName">DNHQ User Name:</label>
              <input
                value={userName}
                ref={userNameRef}
                onChange={() => setUserName(userNameRef.current.value)}
              />
            </div>
          )}
          <Droppable droppableId="unUsed" type="holder">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "#ccc" : "#ddd",
                }}
                className="holder"
                {...provided.droppableProps}
              >
                {unUsed.map((el, i) => (
                  <Draggable
                    key={el.id}
                    index={i}
                    draggableId={el.id}
                    isDragDisabled={voted}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`photo photo--2023`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img alt={el.name} src={el.photo} />
                        <div className="photoCaption">{el.name}</div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        <div className="rankedWrapper">
          <h2>{userName ? `${userName}'s ` : ""}Big Board</h2>
          <Droppable droppableId="ranked" type="holder">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "#ccc" : "#ddd",
                }}
                className="ranked"
                {...provided.droppableProps}
              >
                {ranked.map((el, i) => (
                  <Draggable
                    key={el.id}
                    index={i}
                    draggableId={el.id}
                    isDragDisabled={voted}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`photo photo--2023`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img alt={el.name} src={el.photo} />
                        <div className="photoCaption">{el.name}</div>
                        <div className="rank">
                          <h2>{i + 1}</h2>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        <div className="buttons">
          <button disabled={!userName || voted} onClick={() => submitVote()}>
            submit
          </button>
          <button
            disabled={voted}
            onClick={() => {
              setRanked([]);
              setUnUsed(photos);
              setUserName("");
            }}
          >
            reset
          </button>
        </div>
      </DragDropContext>
    </div>
  );
}

export default Vote;
